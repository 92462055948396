import React, { useContext } from "react";
import Socials from "./partials/Buttons/Socials";
import { DataContext } from "../data"
import { getNotSelectedLanguage } from "../helpers/language";
export default function Navbar({language, toggleLanguage}) {

  const { CTA } = useContext(DataContext);
  const logoSource = '//' + window.location.host + "/sd-it-high-resolution-2.png";
  const notSelectedLanguage = getNotSelectedLanguage(language);

  return (

    <header className="bg-light-black sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap pt-3 px-3 md:p-3 flex-col md:flex-row items-center">
        <div className="flex items-center justify-center w-24 md:w-[100px]">
          <a href="/home" className="md:ml-3 text-xl">
            <img className="w-full h-auto" style={{ 'margin-top': "0px" }} src={logoSource} alt="SD IT Services" />
          </a>
        </div>
        <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-700	flex flex-wrap
         items-center text-base justify-center">
          <a href="/home#projects" className="m-2 hover:text-white">
            {CTA.projects}
          </a>
          <a href="/home#skills" className="m-2 hover:text-white">
            {CTA.skills}
          </a>
          <a href="/home#testimonials" className="m-2 hover:text-white">
            {CTA.education}
          </a>
        </nav>
        <a href="#" onClick={() => toggleLanguage(notSelectedLanguage)} className="m-2 text-sm md:block hidden hover:text-white cursor-pointer">
          {notSelectedLanguage} |
        </a>
        <Socials className={"hidden md:flex"}/>
      </div>
      <a href="#" onClick={() => toggleLanguage(notSelectedLanguage)} className="m-2 text-sm md:hidden absolute top-0 right-0">{notSelectedLanguage}</a>
    </header>
  );
}
