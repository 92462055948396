import React, { useContext } from "react";
import SectionHeader from "../../partials/SectionHeader";
import { DataContext } from "../../../data";
import ProjectTile from "../../partials/ProjectTile";
import { CodeIcon } from '@heroicons/react/solid';


export default function PersonalProjects() {
  const { home, personalProjects } = useContext(DataContext);

  return (
    <section id="personalProjects" className="text-text-color body-font">
      <div className="container px-5 py-5 mx-auto text-center lg:px-40">
        <SectionHeader {...{ title: home.personalProjects.title, icon: <CodeIcon/>, 'description': home.personalProjects.description }} />
        <div className="flex flex-wrap -m-4">
          {personalProjects.map((project) => (
            <ProjectTile key={`personal-${project.id}`}  {...project} />
          ))}
        </div>
      </div>
    </section>
  );
}
