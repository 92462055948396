import { createContext } from "react";

export const DataContext = createContext();

export const projects = [
  {
    id: 99,
    title: "Aviobook Connect X",
    subtitle: "Expert Fullstack Developer",
    techStack: "Typescript, React, Realtime chat",
    features: ["Communicatie app voor vliegtuigpersoneel", "Real time data inclusief flight schedules en weather updates", "Geavanceerde chat features", "Push Notifications", "..."],
    description:
      "Aviobook Connect brengt automatisch een flight crew samen in chatrooms die voor elke vlucht worden aangemaakt en live worden bijgewerkt. Deel live-updates met de crew, volg de flight status, of praat even bij met je crewleden. De app is beschikbaar op iOS en Android.",
    images: ["/aviobook.png"],
    links: { www: "https://www.aviobook.aero/products/connect" }
  },
  {
    id: 0,
    title: "Toyota Customer Portal",
    subtitle: "Techlead",
    techStack: "Fullstack, Cloud",
    features: ["SSO voor login, registratie en reset password", "Opvolging van orders in Vehicle Order Tracker", "Bekijken van onderhoud historie", "Het raadplegen van voertuig specificaties", "Profile sectie, waar gebruikers profielinstellingen kunnen aanpassen", "Over The Air mapupdates", "Connected Servics Activatie", "..."],
    description:
      "Klantenportaal van de Europese Toyota website. In deze full-stack oplossing, gebruikt door miljoenen Toyota eigenaars over geheel Europa, kunnen gebruikers hun Toyota bestelling opvolgen, services activeren en verscheidene wagen specifieke features raadplegen.",
    images: ["/toyota-customer-portal-1.jpg", "/toyota-customer-portal-2.jpg", "/toyota-customer-portal-3.jpg", "/toyota-customer-portal-4.jpg", "/toyota-customer-portal-5.jpg", "/toyota-customer-portal-6.jpg", "/toyota-customer-portal-7.jpg"],
    links: { www: "https://nl.toyota.be/apps/customerportal#/" }
  },
  {
    id: 1,
    title: "Notification Center",
    subtitle: "Techlead",
    techStack: "Fullstack, Cloud",
    features: ["Serverless backend, oneindig schaalbaar", "Opvragen van notifications", "B2B flows", "Het versturen van notificaties in bulk", "Lokalisatie van notifications content via CMS platform"],
    description:
      "Serverless notification systeem waarmee inbox en push notifications gestuurd worden naar Toyota/Lexus klanten. Notifications kunnen aangemaakt worden door business processen of manueel ingepland worden via een B2B Tool.",
    images: ["/notification-center-1.jpg", "/notification-center-2.jpg", "/notification-center-3.jpg"],
    links: { www: "https://nl.lexus.be/#/sso/login" },
  },
  {
    id: 2,
    title: "Subaru SSO",
    subtitle: "Techlead",
    techStack: "Fullstack, Cloud",
    description:
      "Full Stack SSO applicatie, gebruikt door Subaru Care App. Deze webapplicatie wordt gebruikt om Subaru accounts te registeren, wachtwoorden te resetten en profile updates.",
    images: ["/subaru3.PNG", "/subaru2.PNG", "/subaru1.PNG"],
    links: { www: "https://play.google.com/store/apps/details?id=eu.subaru.subarucare&pli=1" },
  },
  {
    id: 3,
    title: "Electrific",
    subtitle: "Fullstack Developer",
    techStack: "Java, Dropwizard, Android (Java), Postgresql, Liquibase",
    description:
      "Een Android app die een totaaloplossing biedt voor eigenaars van elektrische wagens. Dit is een EU Horizon project voor de Europese commissie, in samenwerking met verschillende Europese instellingen.",
    images: ["/electrific.png", "/electrific2.png", "/electrific3.png"],
    links: { www: "https://electrific.eu" },
    disableStrechedImage: false
  },
  {
    id: 4,
    title: "Native Language Identification",
    subtitle: "Master Thesis",
    techStack: "Java, Liblinear, LaTeX",
    features: ["Leenworden", "Lexemen", "Lemmas", "POS", "Letters", "Complexe features"],
    description:
      "In deze master thesis ontwikkelde ik een algoritme dat op basis van een Engelse tekst de moedertaal van de schrijver achterhaalt. Het algoritme gebruikte Support Vector Machines en Liblinear om een model te maken.",
    images: ["/thesis.PNG"],
    links: { www: "https://www.dropbox.com/s/mpl0eur34xpy8ey/finalThesisPresentation.pptx%20%284%29.pptx?dl=0" },
  },
  {
    id: 5,
    title: "Lexus Customer Portal",
    subtitle: "Techlead",
    techStack: "Fullstack, Cloud",
    description:
      "Klantenportaal van de Europese Lexus website. In deze full-stack oplossing kunnen gebruikers hun Lexus order volgen, features van hun gekochte auto's ontdekken en garageafspraken boeken voor hun wagen(s).",
    features: ["SSO voor login, registratie en reset password", "Opvolgen van bestelling in Vehicle Order Tracker", "Bekijken van maintenance historie", "Het raadplegen van voertuig gegevens en specificaties", "Profile sectie, waar gebruikers profielinstellingen kunnen aanpassen", "Over The Air mapupdates - het configureren van navigatie kaarten die zonder manuele overdracht geupdate kunnen worden", "..."],
    images: ["/lexus-customer-portal.PNG", "/lexus-customer-portal-2.jpg"],
    links: { www: "https://nl.lexus.be/#/sso/login" },
  },
];

export const educations = [
  {
    course:
      "Master in Toegepaste Informatica: Artificial Intelligence",
    image: "https://www.imptox.eu/media/5483/ku.png",
    grade: "Onderscheiding",
    institution: "KU Leuven",
  },
  {
    course:
      "Schakelprogramma Toegepaste Informatica",
    image: "https://www.imptox.eu/media/5483/ku.png",
    grade: "Onderscheiding",
    institution: "KU Leuven",
  },
  {
    course:
      "Erasmus IP Program: Forensics and Security",
    image: "./bournemouth.jpg",
    grade: "",
    institution: "Bournemouth University",
  },
  {
    course:
      "Toegepaste Informatica: Applicatieontwikkeling",
    image: "https://www.pxl.be/Assets/website/pxl_algemeen/afbeeldingen/grotere_versie/1314_logo_pxl_bol.png",
    grade: "Grote onderscheiding",
    institution: "PXL Hogeschool Limburg",
  },
];

export const skills = [
  "Typescript",
  "JavaScript",
  "React",
  "React Native",
  "Node.js",
  "Nest.js",
  "Java",
  "Socket.IO",
  "Docker",
  "NLP",
  "Machine Learning",
  "Redux",
  "AWS S3, SNS, SQS, Lambda, Cloudformation",
  "Next.js",
  "Recoil.js",
  "HTML",
  "(S)CSS",
  "MongoDB",
  "SQL",
  "..."
];

export const home = {
  about: {
    intro: "Hallo! Ik ben Seppe, freelance <b>Full Stack Developer</b> en <b>Tech Lead</b>. Op deze website kan je informatie terugvinden over enkele IT projecten waar ik aan bijgedragen heb. Ik doe zowel <b>project management</b>, <b>frontend</b>, <b>backend</b> als <b>cloud infrastructuur</b>.",
    career: "Momenteel ben ik aan de slag als Full Stack Developer, Technical Analyst en Devops deskundige bij Toyota Motors Europe. Als je meer wilt weten over mijn loopbaan, bezoek dan mijn <a class=\"underline\" href=\"https://www.linkedin.com/in/seppedijkmans/?originalSubdomain=be\">LinkedIn pagina</a>."
  },
  projects: {
    title: 'Professionele Projecten',
    description: 'Klik op de tile voor meer info.'
  },
  personalProjects: {
    title: 'Persoonlijke Projecten',
    description: 'Enkele hobby projecten. Klik op de tile voor meer info.'
  },
  skills: {
    title: 'Skills & Technologies',
    description: 'Een beknopt overzicht van mijn skillset.'
  },
  education: {
    title: "Opleiding",
    description: 'Voor een uitgebreider overzicht, bekijk mijn LinkedIn profiel.'
  },
  contact: {
    title: 'Contact'
  }
}

export const CTA = {
  projects: 'Projecten',
  contact: 'Contact',
  education: 'Opleiding',
  skills: 'Skills'
}

export const personalProjects = [
  {
    id: 14,
    title: "Simple Chat App",
    subtitle: "React Native, Socket.Io, Nest.JS",
    techStack: "React Native, Socket.Io, Nest.JS, Typescript, Android, IOS, React Navigation, React Event Emitter",
    features: ["Group Chat", "Websockets", "Input screen to enter username"],
    description:
        "React Native hobby project waarbij de gebruiker deel kan nemen aan een groepschat. Dit is een full stack project, waarbij de backend gebruik maakt van Nest.JS Websocket service.",
    images: ["/chat-app-1.jpg", "/chat-app-2.jpg"],
    links: {
      github: "https://github.com/revolution64/chatapp/tree/master"
    }
  },
  {
    id: 13,
    title: "AI Text Summarizer",
    subtitle: "OpenAI API, ChatGPT, Node.js",
    techStack: "OpenAI API, ChatGPT-3.5, Express.js, Node.js, Render",
    features: ["AI Text Summarization", "Features different languages", "Summary Length Parameters"],
    description:
        "Een AI hobby project waarin een tekst wordt samengevat in een gestructureerde samenvatting. Inclusief de mogelijkheid om de lengte en taal te kiezen van de samenvatting. De frontend werd deels gegenereerd door ChatGPT.",
    images: ["/AI-Text-Summarizer.PNG"],
    links: {
      www: "https://ai-text-summarizer.onrender.com/",
      github: "https://github.com/revolution64/textSummarizer/tree/master"
    }
  },
  {
    id: 12,
    title: "Synoniemen Zoeker",
    subtitle: "Python, Vue.js, Flask, spacy",
    techStack: "Python, Flask, PythonAnywhere, Vue.js, OpenDutchWordNet",
    features: ["Natural Language Processing", "Machine Learning", "POS Tagging", "Lemmatization", "Synoniemen zoeken voor veelgebruikte woorden"],
    description:
      "Deze webapplicatie ontleedt een tekst en probeert via Lemmatization and POS tagging goede synoniemen te vinden voor veel gebruikte woorden. Prototype beschikbaar op aanvraag.",
    images: ["/synoniemenzoeker-0.PNG", "/synoniemenzoeker-1.PNG"],
    links: { www: "https://revolution64.pythonanywhere.com/static/index.html", github: "https://github.com/revolution64/cmt-server" }
  },
  {
    id: 6,
    title: "Pi Weather Station",
    subtitle: "Next.JS, React.JS",
    techStack: "Raspberry Pi, Linux. Next.JS, React.JS, Node.JS",
    features: ["Always-on display", "Live weervoorspellingen", "Luchtvochtigheid", "Luchtdruk", "Tijdsweergave", "Elke dag een andere mop", "Elke dag een andere quote", "Google kalender afspraken"],
    description:
      "Een always-on Raspberry Pi weerstation, inclusief weersvoorspellingen, een mop van de dag en een kalenderfunctie.",
    images: ["/pi-weather-station-2.PNG", "/pi-weather-station-1.jpg", "/pi-weather-station-3.PNG", "/pi-weather-station-4.PNG", "/pi-weather-station-5.PNG"],
    links: { github: "https://bitbucket.org/revolution64/pi-display" }
  },
  {
    id: 7,
    title: "Pi Powered Game Boy Advance",
    subtitle: "Raspberry Pi",
    techStack: "Raspberry Pi, Linux",
    description:
      "Een custom made Game Boy Advance, aangestuurd door een Raspberry Pi Zero en een FreePlay circuit board.",
    images: ["/pi-gba-1.PNG", "/pi-gba-2.jpg"],
  },
  {
    id: 8,
    title: "Pi Robot",
    subtitle: "Websocket, Express.JS, React.JS, Linux",
    techStack: "Websocket, Express.JS, React, Node.JS, Linux, Raspberry Pi",
    features: ["Op afstand bestuurbare robot", "React UI met livestream", "Keyboard besturing", "Responsive Interface"],
    description:
      "Raspberry Pi Robot met camera, inclusief remote besturing en livestream via een React applicatie.",
    images: ["/pi-robot-car.jpg"],
    links: {  github: "https://bitbucket.org/revoblog/pi-robot-express" },
  },
  {
    id: 9,
    title: "Pi Night Camera",
    subtitle: "Linux, Node, Pi Camera",
    techStack: "Node, Linux, Pi Camera",
    description:
      "Raspberry Pi camera met night vision en motion detection.",
    images: ["/pi-night-camera.jpg"],
  },
  {
    id: 10,
    title: "Pi Print Server",
    subtitle: "Linux, CUPS",
    techStack: "Linux, CUPS",
    description:
      "In dit project transformeerde ik een oude printer om tot een netwerkprinter, door middel van een Pi Zero en een CUPS print server.",
    images: ["/pi-print-server.PNG"]
  },
  {
    id: 11,
    title: "Mr White",
    subtitle: "React",
    techStack: "React, SCSS",
    description:
      "Een React versie van het spel Undercover: the Forgetful Spy.",
    images: ["/mrwhite-1.PNG", "/mrwhite-2.PNG", "/mrwhite-3.PNG", "/mrwhite-4.PNG"],
    links: { github: "https://github.com/revolution64/mrWhite"}
  }
];

export const allProjects = projects.concat(personalProjects);
