import React from "react";

function ProjectTile({ id, image, subtitle, title, description }) {

 const projectLink = `//${window.location.host}/project/${id}`

  return (<a href={projectLink} key={image} className="sm:w-1/2 w-100 p-4 self-stretch flex">
    <div className="flex w-full">
      <div className="px-8 py-10 w-full border-2 border-gray-400 bg-light-black hover:border-yellow-400 hover:bg-gray-700">
        <h2 className="tracking-widest text-sm title-font font-medium text-yellow-200 mb-1">
          {subtitle}
        </h2>
        <h1 className="title-font text-lg font-medium text-white mb-3">
          {title}
        </h1>
        <p className="leading-relaxed">{description}</p>
      </div>
    </div>
  </a>);
}

export default ProjectTile;