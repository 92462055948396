import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Navbar from "./components/Navbar";
import ProjectDetailPage from "./components/pages/project-details/ProjectDetailPage";
import * as Data from "./data";
import * as EnglishData from "./data_en";
import {useLanguage} from "./hooks/useLanguage.js";


export default function App() {
  const [language, toggleLanguage] = useLanguage();
  const siteData = language === 'EN' ? EnglishData : Data;


  return (
    <Data.DataContext.Provider value={siteData}>
      <div className="text-text-color bg-black body-font">
        <Navbar language={language} toggleLanguage={toggleLanguage} />
        <BrowserRouter>
          <Routes>
            <Route exact path="/" element={<Home displaySiteInEnglish />} />
            <Route exact path="/project/:id" element={
              <ProjectDetailPage />
            } />
            <Route index element={<Home />} />
            <Route
              path="*"
              element={<Home />}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </Data.DataContext.Provider>
  );
}
