import React from 'react';

export default function EducationTile({ education }) {
  return (<div className="p-4 md:w-1/2 w-full flex-row ">
    <div className="h-full bg-light-black p-8 rounded flex justify-center border-2 border-gray-400">
      <img alt="testimonial" src={education.image} className="w-12 rounded-full flex-shrink-0 object-cover basis-1/4 hidden xl:flex" style={{
        width: "20%"
      }} />
      <div className="flex flex-col w-100% md:w-4/5">
        <p className="leading-relaxed md:mt-6 ml-1 text-yellow-200">{education.course}</p>
        <div className="inline-flex items-center">
          <span className="flex-grow flex flex-col md:pl-4 flex-grow">
            <span className="title-font font-medium p-1 text-white">
              {education.institution}
            </span>
            <span className="text-gray-500 text-sm uppercase">
              {education.grade}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>);
}
